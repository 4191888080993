// action - state management
import { LOGIN, LOGOUT, SET_USER, SET_LOGIN, SET_ERROR } from './actionType';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    errors: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case SET_USER: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: false,
                isInitialized: true,
                user
            };
        }
        case SET_LOGIN: {
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                errors: action.payload
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
