// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconNotes,
    IconUserCircle,
    IconChecklist,
    IconPlaylistAdd,
    IconClipboardList,
    IconListDetails,
    IconArrowsSort,
    IconReport,
    IconList,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconPalette,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconReceipt2,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconDeviceDesktop,
    IconDiscount2,
    IconBuildingStore,
    IconBell,
    IconMail,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp,
    IconBox,
    IconPhoto
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconNotes,
    IconUserCircle,
    IconListDetails,
    IconChecklist,
    IconPlaylistAdd,
    IconClipboardList,
    IconArrowsSort,
    IconFileAnalytics,
    IconReceipt,
    IconDiscount2,
    IconShoppingCartDiscount,
    IconTicket,
    IconList,
    IconPalette,
    IconDeviceDesktop,
    IconReport,
    IconReceipt2,
    IconQuestionMark,
    IconSettings,
    IconBuildingStore,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconMail,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp,
    IconBox,
    IconPhoto
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            icon: icons.IconArrowsSort,
            url: '/orders',
            permissionID: 'Orders-read'
        },
        {
            id: 'sellers',
            title: <FormattedMessage id="sellers" />,
            type: 'item',
            icon: icons.IconBuilding,
            url: '/sellers',
            permissionID: 'sellers-read'
        },
        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/customers',
            permissionID: 'Customers-read'
        },
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            icon: icons.IconNotes,
            url: '/products',
            permissionID: 'Product-read'
        },
        {
            id: 'transactions',
            title: <FormattedMessage id="transactions" />,
            type: 'item',
            icon: icons.IconReceipt2,
            url: '/transactions',
            permissionID: 'Transaction-read'
        },
        // {
        //     id: 'reports',
        //     title: <FormattedMessage id="reports" />,
        //     type: 'item',
        //     icon: icons.IconReport,
        //     url: '/reports',
        //     permissionID: 'Reports-read'
        // },
        {
            id: 'support-tickets',
            title: <FormattedMessage id="support-tickets" />,
            type: 'item',
            icon: icons.IconTicket,
            url: '/support-tickets',
            permissionID: 'SupportTicket-read'
        },
        {
            id: 'contacts',
            title: <FormattedMessage id="contacts" />,
            type: 'item',
            icon: icons.IconMessage2,
            url: '/contacts',
            permissionID: 'Contacts-read'
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/users',
            permissionID: 'Users-read'
        },
        {
            id: 'brands',
            title: <FormattedMessage id="brands" />,
            type: 'item',
            icon: icons.IconBox,
            url: '/brands',
            permissionID: 'Brands-read'
        },
        {
            id: 'coupons',
            title: <FormattedMessage id="coupons" />,
            type: 'item',
            icon: icons.IconDiscount2,
            url: '/coupons',
            permissionID: 'Coupons-read'
        },
        {
            id: 'colors',
            title: <FormattedMessage id="colors" />,
            type: 'item',
            icon: icons.IconPalette,
            url: '/colors',
            permissionID: 'Colors-read'
        },
        {
            id: 'gallery-images',
            title: <FormattedMessage id="gallery-images" />,
            type: 'item',
            icon: icons.IconPhoto,
            url: '/media',
            permissionID: 'Media-read'
        },
        {
            id: 'category',
            title: <FormattedMessage id="category" />,
            type: 'collapse',
            icon: icons.IconList,
            permissionID: 'Category-read',
            children: [
                {
                    id: 'products-categories',
                    title: <FormattedMessage id="products-categories" />,
                    type: 'item',
                    icon: icons.IconList,
                    url: '/products-categories',
                    permissionID: 'Products-categories-read'
                },
                {
                    id: 'products-sub-categories',
                    title: <FormattedMessage id="products-sub-categories" />,
                    type: 'item',
                    icon: icons.IconList,
                    url: '/products-sub-categories',
                    permissionID: 'Products-sub-categories-read'
                }
            ]
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: icons.IconSettings,
            permissionID: 'Setting-read',
            children: [
                // {
                //     id: 'website-setting',
                //     title: <FormattedMessage id="website-setting" />,
                //     type: 'item',
                //     icon: icons.IconDeviceDesktop,
                //     url: '/website-setting',
                //     permissionID: 'Website-Setting-read'
                // },
                {
                    id: 'marketplace-setting',
                    title: <FormattedMessage id="marketplace-setting" />,
                    type: 'item',
                    icon: icons.IconBuildingStore,
                    url: '/marketplace-setting/general-setting/general_setting/edit',
                    permissionID: 'Marketplace-Setting-read'
                },
                {
                    id: 'newsletter-notification',
                    title: <FormattedMessage id="newsletter-notification" />,
                    type: 'item',
                    icon: icons.IconMail,
                    url: '/newsletter',
                    permissionID: 'Newsletter-Notification-read'
                },
                {
                    id: 'notification',
                    title: <FormattedMessage id="push-notification" />,
                    type: 'item',
                    icon: icons.IconBell,
                    url: '/notification',
                    permissionID: 'Notification-write'
                }
            ]
        }
    ]
};

export default pages;
