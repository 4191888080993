import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Profile Routing
const AppProfile = Loadable(lazy(() => import('views/profile/Profile')));
const UpdateProfile = Loadable(lazy(() => import('views/profile/UpdateProfile')));
const UpdateSecurity = Loadable(lazy(() => import('views/profile/UpdateSecurity')));
const UpdateKYC = Loadable(lazy(() => import('views/sellers/UpdateKYC')));
const UpdateKYCDetail = Loadable(lazy(() => import('views/profile/UpdateKYCDetail')));

// Companies/Seller Routing
const AppSellersList = Loadable(lazy(() => import('views/sellers/SellersList')));
const AppUpdateSellers = Loadable(lazy(() => import('views/sellers/UpdateSellers')));
const AppSellerDetails = Loadable(lazy(() => import('views/sellers/SellerDetails')));

// Wallet
const AppSellerWallet = Loadable(lazy(() => import('views/wallet/SellerWallet')));

// Customers Routing
const AppCustomerList = Loadable(lazy(() => import('views/customers/CustomersList')));
const AppUpdateCustomer = Loadable(lazy(() => import('views/customers/UpdateCustomer')));
const AppCustomerDetails = Loadable(lazy(() => import('views/customers/CustomerDetails')));

// Users Routing
const AppUserList = Loadable(lazy(() => import('views/users/UsersList')));
const AppUpdateUser = Loadable(lazy(() => import('views/users/UpdateUser')));

// Brands Routing
const AppBrandList = Loadable(lazy(() => import('views/brands/BrandList')));
const AppUpdateBrand = Loadable(lazy(() => import('views/brands/UpdateBrand')));

// Coupon Routing
const AppCouponList = Loadable(lazy(() => import('views/coupons/CouponList')));
const AppUpdateCoupon = Loadable(lazy(() => import('views/coupons/UpdateCouponCode')));

// Color Routing
const AppColorList = Loadable(lazy(() => import('views/colors/ColorList')));
const AppUpdateColor = Loadable(lazy(() => import('views/colors/UpdateColorName')));

// Products Routing
const AppProductList = Loadable(lazy(() => import('views/products/ProductList')));
const AppUpdateProduct = Loadable(lazy(() => import('views/products/UpdateProduct')));
const AppProductDetails = Loadable(lazy(() => import('views/products/ProductDetails')));
const AppProductBasicDetails = Loadable(lazy(() => import('views/products/BasicDetails')));
const AppProductVariation = Loadable(lazy(() => import('views/products/ProductVariation')));
const AppUpdateProductVariation = Loadable(lazy(() => import('views/products/UpdateProductVariation')));
const AppProductReview = Loadable(lazy(() => import('views/products/ProductReview')));

// Products Category Routing
const AppProductCategoryList = Loadable(lazy(() => import('views/categories/product-categories/ProductCategoryList')));
const AppUpdateProductCategory = Loadable(lazy(() => import('views/categories/product-categories/UpdateProductCategory')));

// Products Sub Category Routing
const AppProductSubCategoryList = Loadable(lazy(() => import('views/categories/products-sub-categories/ProductSubCategoryList')));
const AppUpdateProductSubCategory = Loadable(lazy(() => import('views/categories/products-sub-categories/UpdateProductSubCategory')));

// Website Setting Routing
const AppWebsiteSettings = Loadable(lazy(() => import('views/settings/website-setting/WebsiteSetting')));

// Newsletter Setting Routing
const AppNewsletter = Loadable(lazy(() => import('views/settings/newsletter/AllNewsletter')));
const AppCreateNewsletter = Loadable(lazy(() => import('views/settings/newsletter/CreateNewsletter')));

// Media Routing
const AppMediaList = Loadable(lazy(() => import('views/media/MediaList')));
const AppMediaAdd = Loadable(lazy(() => import('views/media/AddMedia')));

// Marketplace Setting Routing
const AppMarketplaceSettings = Loadable(lazy(() => import('views/settings/marketplace-settings/MarketplaceSettings')));
const AppBannerSlider = Loadable(lazy(() => import('views/settings/marketplace-settings/BannerSlider')));
const AppBannerSliderUpdate = Loadable(lazy(() => import('views/settings/marketplace-settings/UpdateBannerSlider')));
const AppUspContent = Loadable(lazy(() => import('views/settings/marketplace-settings/UspContent')));
const AppUspContentUpdate = Loadable(lazy(() => import('views/settings/marketplace-settings/UpdateUspContent')));

const AppCtaSection = Loadable(lazy(() => import('views/settings/marketplace-settings/CtaSection')));
const AppUpdateCtaSection = Loadable(lazy(() => import('views/settings/marketplace-settings/UpdateCtaSection')));
const AppAllAdvertisement = Loadable(lazy(() => import('views/settings/marketplace-settings/AllAdvertisement')));
const AppUpdateAdvertisement = Loadable(lazy(() => import('views/settings/marketplace-settings/UpdateAdvertisement')));
const AppAllWebMenu = Loadable(lazy(() => import('views/settings/marketplace-settings/WebMenu')));
const AppUpdateWebMenu = Loadable(lazy(() => import('views/settings/marketplace-settings/UpdateWebMenu')));
const AppUpdateNotification = Loadable(lazy(() => import('views/settings/notification/NotificationForm')));
const AppUpdateGeneralSetting = Loadable(lazy(() => import('views/settings/marketplace-settings/GeneralSetting')));
const AppUpdateAcceptedPincode = Loadable(lazy(() => import('views/settings/marketplace-settings/AcceptedPincode')));
const AppOthersSetting = Loadable(lazy(() => import('views/settings/marketplace-settings/OtherSetting')));

// Orders Routing
const AppOrderList = Loadable(lazy(() => import('views/orders/OrdersList')));
const AppOrderDetails = Loadable(lazy(() => import('views/orders/OrderDetails')));

// Reports Routing
const AppReports = Loadable(lazy(() => import('views/reports/Report')));

// Reports Routing
const AppTransactions = Loadable(lazy(() => import('views/transaction/TransactionList')));

// Tickets Routing
const AppTickets = Loadable(lazy(() => import('views/ticket/SupportTickets')));
const AppCreateTickets = Loadable(lazy(() => import('views/ticket/CreateSupportTicket')));
const AppViewTicket = Loadable(lazy(() => import('views/ticket/ViewTicket')));

// Constacts Routing
const AppContactList = Loadable(lazy(() => import('views/contacts/ContactList')));
const AppContactDetails = Loadable(lazy(() => import('views/contacts/ViewContact')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            state: { permissionID: 'All' },
            element: (
                <AuthGuard>
                    <RoleGuard userPermission={['admin', 'seller']}>
                        <Dashboard />
                    </RoleGuard>
                </AuthGuard>
            )
        },
        {
            path: '/dashboard',
            state: { permissionID: 'All' },
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <Dashboard />
                </RoleGuard>
            )
        },
        {
            path: '/sellers',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppSellersList />
                </RoleGuard>
            )
        },
        {
            path: '/sellers/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateSellers pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/sellers/:sellersID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateSellers pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/sellers/:sellersID',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppSellerDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/sellers/:sellersID/update-kyc',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <UpdateKYC pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppCustomerList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateCustomer pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateCustomer pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerID',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppCustomerDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/users',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUserList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/users/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateUser pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/users/:userID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateUser pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/users/:userID',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateUser pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/orders',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppOrderList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/orders/:orderID',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppOrderDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/products',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppProductList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/products/add',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppUpdateProduct pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/products/:productID/edit',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppUpdateProduct pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/products/:productID',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppProductDetails pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/products/:productID/',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppProductBasicDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/products/:productID/basic',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppProductBasicDetails pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/products/:productID/variation',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppProductVariation pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/products/:productID/variation/add',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppUpdateProductVariation pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/products/:productID/variation/:productVariationID/edit',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppUpdateProductVariation pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/products/:productID/review',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <AppProductReview pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/wallet',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppSellerWallet pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/products-categories',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppProductCategoryList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/products-categories/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateProductCategory pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/products-categories/:productCategoryID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateProductCategory pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/products-sub-categories',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppProductSubCategoryList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/products-sub-categories/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateProductSubCategory pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/products-sub-categories/:productSubCategoryID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateProductSubCategory pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/brands',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppBrandList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/brands/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateBrand pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/brands/:brandID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateBrand pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/coupons',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppCouponList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/coupons/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateCoupon pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/coupons/:couponID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateCoupon pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/colors',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppColorList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/colors/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateColor pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/colors/:colorID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateColor pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/contacts',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppContactList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contacts/:contactID',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppContactDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppTickets pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets/add',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppCreateTickets pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets/:ticketID',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppViewTicket pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/reports',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppReports pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/transactions',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppTransactions pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/website-setting',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppWebsiteSettings pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/marketplace-setting',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppMarketplaceSettings pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/marketplace-setting',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppBannerSlider pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/banners',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppBannerSlider pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/banners/add',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppBannerSliderUpdate pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/banners/:bannerSliderID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppBannerSliderUpdate pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/usp',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUspContent pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/usp/add',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUspContentUpdate pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/usp/:uspID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUspContentUpdate pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/cta',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppCtaSection pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/cta/add',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateCtaSection pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/cta/:ctaID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateCtaSection pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/advertisement',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppAllAdvertisement pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/advertisement/add',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateAdvertisement pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/advertisement/:advertisementID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateAdvertisement pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/web-menu',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppAllWebMenu pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/webMenu/:webMenuID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateWebMenu pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/accepted-pincode',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateAcceptedPincode />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/general-setting/:generalSettingID/edit',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppUpdateGeneralSetting pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/marketplace-setting/others',
                    element: (
                        <RoleGuard userPermission={['admin']}>
                            <AppOthersSetting pageBehaviour="Add" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/notification',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppUpdateNotification pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/newsletter',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppNewsletter pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/newsletter/add',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppCreateNewsletter pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/newsletter/:newsletterID/edit',
            element: (
                <RoleGuard userPermission={['admin']}>
                    <AppCreateNewsletter pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/media',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppMediaAdd pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/media/add',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppMediaAdd pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/profile',
            element: (
                <RoleGuard userPermission={['admin', 'seller']}>
                    <AppProfile pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/profile',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <UpdateProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-profile',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <UpdateProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-security',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <UpdateSecurity pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/kyc-detail',
                    element: (
                        <RoleGuard userPermission={['admin', 'seller']}>
                            <UpdateKYCDetail pageBehaviour="Add" />
                        </RoleGuard>
                    )
                }
            ]
        }
    ]
};

export default MainRoutes;
