import adminPages from './pages';
import sellerPages from './sellerPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    adminPages,
    sellerPages
};

export default menuItems;
