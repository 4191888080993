// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconNotes,
    IconUserCircle,
    IconChecklist,
    IconPlaylistAdd,
    IconClipboardList,
    IconListDetails,
    IconArrowsSort,
    IconReport,
    IconList,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconWallet,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconDeviceDesktop,
    IconBuildingStore,
    IconBell,
    IconReceipt2,
    IconMail,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp,
    IconPhoto
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconNotes,
    IconUserCircle,
    IconListDetails,
    IconChecklist,
    IconWallet,
    IconPlaylistAdd,
    IconReceipt2,
    IconClipboardList,
    IconArrowsSort,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconList,
    IconDeviceDesktop,
    IconReport,
    IconQuestionMark,
    IconSettings,
    IconBuildingStore,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconMail,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp,
    IconPhoto
};

// ==============================|| SELLER PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            icon: icons.IconArrowsSort,
            url: '/orders',
            permissionID: 'Orders-read'
        },
        // {
        //     id: 'customers',
        //     title: <FormattedMessage id="customers" />,
        //     type: 'item',
        //     icon: icons.IconUsers,
        //     url: '/customers',
        //     permissionID: 'Customers-read'
        // },
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            icon: icons.IconNotes,
            url: '/products',
            permissionID: 'Product-read'
        },
        // {
        //     id: 'transactions',
        //     title: <FormattedMessage id="transactions" />,
        //     type: 'item',
        //     icon: icons.IconReceipt2,
        //     url: '/wallet-transactions',
        //     permissionID: 'Transaction-read'
        // },
        {
            id: 'payments',
            title: <FormattedMessage id="payments" />,
            type: 'item',
            icon: icons.IconWallet,
            url: '/wallet',
            permissionID: 'Wallet-read'
        },
        // {
        //     id: 'reports',
        //     title: <FormattedMessage id="reports" />,
        //     type: 'item',
        //     icon: icons.IconReport,
        //     url: '/reports',
        //     permissionID: 'Reports-read'
        // },
        {
            id: 'support-tickets',
            title: <FormattedMessage id="support-tickets" />,
            type: 'item',
            icon: icons.IconTicket,
            url: '/support-tickets',
            permissionID: 'SupportTicket-read'
        },
        {
            id: 'gallery-images',
            title: <FormattedMessage id="gallery-images" />,
            type: 'item',
            icon: icons.IconPhoto,
            url: '/media',
            permissionID: 'Media-read'
        }
    ]
};

export default pages;
