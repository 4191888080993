import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// routes
import AuthenticationRotes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

const AppInvoicePage = Loadable(lazy(() => import('views/orders/Invoice')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([LoginRoutes, AuthenticationRotes, MainRoutes, { path: '/invoice/:orderID', element: <AppInvoicePage /> }]);
}
