import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SET_ERROR, SET_USER } from 'store/actionType';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

import { globalAction } from 'store';
// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    errors: null
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const storeDispatch = useDispatch();
    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                setSession(serviceToken);
                if (serviceToken) {
                    const response = await axios.get('/users/validate-token');
                    setSession(response.data?.access_token);
                    if (response.data?.access_token) {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: response.data.user
                            }
                        });
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const refreshUser = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            setSession(serviceToken);
            if (serviceToken) {
                const response = await axios.get('/users/validate-token');
                setSession(response.data?.access_token);
                if (response.data?.access_token) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: response.data.user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: LOGOUT
            });
        }
    };

    const login = async (email, password) => {
        try {
            const response = await axios.post('/login', { email, password });
            if (response.data?.user?.roles[0]?.id === 3) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'You have not access this Panel' }));
                return;
            }
            if (response.data?.access_token) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: 'success', message: 'Login Successfully' }));
                setSession(response.data.access_token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.data.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: error?.message }));
            if (error?.message === 'The User is not verified') {
                // dispatch({
                //     type: SET_ERROR,
                //     payload: {
                //         errors: error?.message
                //     }
                // });
                navigate('/code-verify', { state: { varificationEmail: email } });
            }
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const sendOtp = async (email) => {
        await axios.post('/users/send-otp', email);
    };

    const loginWithOTP = async (email, otp) => {
        try {
            let response = await axios.post('/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const verifyOTP = async (email, otp) => {
        try {
            let response = await axios.post('/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }
            if (response.status === 'success') {
                setSession(response.accessToken);
                navigate('/reset-password', { replace: true, state: otp });
                dispatch({
                    type: SET_USER,
                    payload: {
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const resetPassword = async (values) => {
        try {
            let response = await axios.post('/users/forget-password', values);
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{ ...state, refreshUser, login, logout, sendOtp, verifyOTP, resetPassword, updateProfile, loginWithOTP }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
